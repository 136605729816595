import tw, { css } from 'twin.macro'

import { PlainHero as PlainHeroType } from '@/types/plain-hero'

import { useWebsite } from '@/hooks/use-website'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'

import { RenderMarkdown } from '@/lib/render-markdown'

export function PlainHero({ data }: { data: PlainHeroType }) {
  const { strapline, heading, copy, theme } = data

  const textTheme: string = theme || 'light'

  const website = useWebsite()

  return (
    <Container
      variant="full"
      css={[
        website === 'the-palm-beach' && textTheme === 'dark' && tw`bg-black`,
        website === 'crockfords' && tw`pb-20`,
      ]}>
      <Container css={[tw`py-10 text-center`]}>
        {strapline && (
          <p
            css={[
              tw`mb-1`,
              website === 'genting-casinos' && tw`text-theme-inverse`,
              website === 'crockfords' && tw`text-theme-inverse`,
              website === 'the-palm-beach' &&
                ((textTheme === 'dark' && tw`text-theme-primary`) ||
                  (textTheme === 'light' && tw`text-theme-inverse`)),
            ]}>
            {strapline}
          </p>
        )}
        <Heading
          as="h1"
          variant="h2"
          css={[
            website === 'genting-casinos' && tw`text-theme-accent`,
            website === 'crockfords' && tw`text-theme-accent`,
            website === 'the-palm-beach' &&
              ((textTheme === 'dark' && tw`text-theme-primary`) ||
                (textTheme === 'light' && tw`text-theme-accent`)),
          ]}>
          <RenderMarkdown>{heading}</RenderMarkdown>
        </Heading>

        {copy && (
          <>
            <hr
              css={[
                tw`my-8 mx-auto border-b max-width[12rem]`,
                website === 'genting-casinos' && tw`border-theme-secondary`,
                website === 'crockfords' && tw`border-theme-accent`,
                website === 'the-palm-beach' &&
                  ((textTheme === 'dark' && tw`border-theme-primary`) ||
                    (textTheme === 'light' && tw`border-theme-secondary`)),
              ]}
            />

            <div
              css={[
                tw`font-light mx-auto max-width[32rem]`,
                website === 'genting-casinos' && tw`text-theme-inverse`,
                website === 'crockfords' && tw`text-theme-inverse`,
                website === 'the-palm-beach' &&
                  ((textTheme === 'dark' && tw`text-theme-primary`) ||
                    (textTheme === 'light' && tw`text-theme-secondary`)),
              ]}>
              <RenderMarkdown>{copy}</RenderMarkdown>
            </div>
          </>
        )}
      </Container>
    </Container>
  )
}
